import { useAddProject } from "@/store/addProject"
import { useRoute } from "vue-router"
import { useCompany } from "./../store/company"
import { useCurrentUser } from "./../store/currentUser"
import { useType } from "./../store/type"
import { useUsers } from "./../store/users"

export async function setAppData() {
  try {
    const companyStore = useCompany()
    const usersStore = useUsers()
    const typeStore = useType()
    const currentUserStore = useCurrentUser()
    const addProjectStore = useAddProject()

    await currentUserStore.setAll()
    await companyStore.setAllCompaniesViaApi(currentUserStore.jwtKey)
    await companyStore.setStandardCompany(currentUserStore.currentUser.main_company.id, currentUserStore.jwtKey, false)
    await usersStore.setUserViaLocaleStorageOrApi(companyStore.activeCompany.id.toString(), currentUserStore.jwtKey),
    await typeStore.setAllTypesViaApi(currentUserStore.jwtKey, companyStore.activeCompany.id.toString())
    addProjectStore.setAll(companyStore.activeCompany.id.toString())
  } catch (e) {
    console.error(e)
  }
}