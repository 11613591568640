import User from '@/interfaces/User'
import { Storage } from '@capacitor/storage'
import axios from 'axios'
import { defineStore } from 'pinia'


export const useUsers = defineStore('users', {
  state: () => ({
    users: [] as User[]
  }),
  actions: {
    async setUserViaLocaleStorageOrApi(activeCompanyId: string, jwtKey: string) {
      const value = (await Storage.get({ key: 'users' })).value
      const usersValue: SavedUsers = value ? JSON.parse(value) : undefined

      if(usersValue && usersValue.renewAt > new Date().getTime())  {
        this.users = usersValue.users
      } else {
        await this.setUsersViaApi(activeCompanyId, jwtKey)
      }
    },
    async setUsersViaApi(activeCompanyId: string, jwtKey: string) {
      const response = await axios.get(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + 'jwt/users?company=' + activeCompanyId, {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })
      const users: User[] = response.data.data
      await Storage.set({ key: 'users', value: JSON.stringify({ users: users, renewAt: new Date().getTime() + 10 * 60 * 60 * 1000 }) })
      this.users = users
    },
    setUsers(users: User[]) {
      this.users = users
    }
  }
})

interface SavedUsers {
  renewAt: number,
  users: User[]
}