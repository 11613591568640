import TitleAndValue from "@/interfaces/TitleAndValue"
import AdditionalData from "@/interfaces/additionalData/AdditionalData"
import axios from "axios"
import { defineStore } from "pinia"
import Tag from "@/interfaces/Tag"

export const useType = defineStore('type', {
  state: () => ({
    statusTypes: [
      { titel: 'Alle', value: 'all' },
      { titel: 'Offen', value: 'open' },
      { titel: 'In Bearbeitung', value: 'in_progress' },
      { titel: 'Wartend', value: 'waiting' },
      { titel: 'Abgeschlossen', value: 'closed' },
      { titel: 'Abgebrochen', value: 'canceled' },
    ] as TitleAndValue[],
    projectTypes: [] as TitleAndValue[],
    tagTypes: [] as Tag[],
    checkpointTypes: [] as TitleAndValue[],
    calculatorTypes: [] as TitleAndValue[],
    teamTypes: [] as TitleAndValue[],
    functionTypes: [] as TitleAndValue[],
    additionalDataTypes: [] as AdditionalData[]
  }),
  getters: {
    getOneStatustype: (state) => {
      return (id: string) => state.statusTypes.find(statusType => statusType.value === id)
    },
    getOneProjectType: (state) => {
      return (id: string) => state.projectTypes.find(projectType => projectType.value === id) 
    },
    getOneTagType: (state) => {
      return (id: string) => state.tagTypes.find(tagType => tagType._id === id) 
    },
    getOneCheckpointType: (state) => {
      return (id: string) => state.checkpointTypes.find(checkpointType => checkpointType.value === id) 
    },
    getOneCalculatorType: (state) => {
      return (id: string) => state.calculatorTypes.find(calculatorType => calculatorType.value === id) 
    },
    getOneTeamType: (state) => {
      return (id: string) => state.teamTypes.find(teamType => teamType.value === id) 
    },
    getOneFuncionType: (state) => {
      return (id: string) => state.functionTypes.find(functionType => functionType.value === id)
    },
    getOneAdditionalDataType: (state) => {
      return (id: string) => {
        const foundType = state.projectTypes.find(projectType => projectType.value === id)
        return state.additionalDataTypes.find(additionalDataType => additionalDataType.label === foundType?.titel)
      }
    }
  },
  actions: {
    async setAllTypesViaApi(jwtKey: string, activeCompanyId: string) {
      await Promise.all([
        this.setProjectTypesViaApi(jwtKey, activeCompanyId),
        this.setTagTypesViaApi(jwtKey, activeCompanyId),
        this.setCheckpointTypesViaApi(jwtKey, activeCompanyId),
        this.setCalculatorTypesViaApi(jwtKey, activeCompanyId),
        this.setTeamTypesViaApi(jwtKey, activeCompanyId),
        this.setFunctionsViaApi(jwtKey, activeCompanyId)
      ])
    },
    async setProjectTypesViaApi(jwtKey: string, activeCompanyId: string) {
      try {
        const response = await axios.get(process.env.VUE_APP_PROJECT_API_BASE_URL + 'type/?company=' + activeCompanyId, {
          headers: {
            Authorization: 'Bearer ' + jwtKey
          }
        })
  
        const types: TitleAndValue[] = []
        for(const type of response.data.data) types.push({ titel: type.name, value: type._id })
        this.projectTypes = types
        await this.setAdditionalDataTypes(jwtKey, activeCompanyId)
      } catch (e) {
        console.error(e)
      }  
    },
    async setTagTypesViaApi(jwtKey: string, activeCompanyId: string) {
      const tags: Tag[] = (await axios.get(process.env.VUE_APP_PROJECT_API_BASE_URL + 'tag/?company=' + activeCompanyId, {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })).data.data
      this.tagTypes = tags
    },
    async setCheckpointTypesViaApi(jwtKey: string, activeCompanyId: string) {
      const response = await axios.get(process.env.VUE_APP_PROJECT_API_BASE_URL + 'checkpoint/?company=' + activeCompanyId, {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })
      const types: TitleAndValue[] = []
      for(const checkpoint of response.data.data) types.push({ titel: checkpoint.name, value: checkpoint._id })
      this.checkpointTypes = types
    },
    async setCalculatorTypesViaApi(jwtKey: string, activeCompanyId: string) {
      const response = await axios.get(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + 'jwt/company/' + activeCompanyId + '/calculators', {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })
      const types: TitleAndValue[] = []
      for(const calculator of response.data.data) types.push({ titel: calculator.last_name + ', ' + calculator.first_name, value: calculator.id.toString() })
      this.calculatorTypes = types
    },
    async setTeamTypesViaApi(jwtKey: string, activeCompanyId: string) {
      const response = await axios.get(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + 'jwt/company/' + activeCompanyId + '/divisions', {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })
      const types: TitleAndValue[] = []
      for(const team of response.data.data) types.push({ titel: team.name, value: team.id.toString() })
      this.teamTypes = types
    },
    async setFunctionsViaApi(jwtKey: string, activeCompanyId: string) {
      const response = await axios.get(process.env.VUE_APP_CUSTOMER_API_BASE_URL + 'function/subcustomer?company_id=' + activeCompanyId, {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })
      const functions: TitleAndValue[] = []
      for(const item of response.data.data) functions.push({ titel: item.name, value: item._id })
      this.functionTypes = functions
    },
    async setAdditionalDataTypes(jwtKey: string, activeCompanyId: string) {
      const additionalDataTypes: AdditionalData[] = []
      for(const projectType of this.projectTypes) {
        const res = await axios.get(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + "api/company/" + activeCompanyId + "/project/additional-fields/definition/" + projectType.value, {
          headers: {
            'Authorization': 'Bearer ' + jwtKey
          }
        })
        additionalDataTypes.push(res.data)
      }
      this.additionalDataTypes = additionalDataTypes
    }
  }
})