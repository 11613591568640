import Company from '@/interfaces/Company'
import { Storage } from '@capacitor/storage';
import axios from 'axios';
import { defineStore } from 'pinia'
import { useAddProject } from './addProject'
import { useType } from './type';

export const useCompany = defineStore('company', {
  state: () => ({
    activeCompany: {} as Company,
    allCompanies: [] as Company[],
  }),
  actions: {
    async setStandardCompany(standardCompanyId: number, jwtKey: string, setFilters: boolean) {
      const currentCompanyJSON = (await Storage.get({ key: 'activeCompany' })).value
      if(currentCompanyJSON) {
        const currentCompany: string = JSON.parse(currentCompanyJSON)
        await this.setActiveCompany(parseInt(currentCompany), jwtKey, setFilters)
      } else {
        await this.setActiveCompany(standardCompanyId, jwtKey, setFilters)
      }
      
    },
    async setActiveCompany(companyId: number, jwtKey: string, setFilters: boolean) {
      const foundCompany: Company | undefined = this.allCompanies.find((company: Company) => {
        return company.id == companyId;
      });

      if(foundCompany !== undefined) {
        await Storage.set({ key: 'activeCompany', value: JSON.stringify(foundCompany.id) })
        this.activeCompany = foundCompany
        if(setFilters) {
          useAddProject().$reset()
          useAddProject().setAll(foundCompany.id.toString())
          await useType().setAllTypesViaApi(jwtKey, foundCompany.id.toString())
        }
        return
      }
      console.error("company not found")
    },
    async setAllCompaniesViaApi(jwtKey: string) {
      try {
        const response = await axios.get(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + 'jwt/companies', {
          headers: {
            Authorization: 'Bearer ' + jwtKey
          }
        })
        const companies: Company[] = response.data.data
        this.setAllCompanies(companies)
      } catch (e) {
        console.error(e)
      }
    },
    setAllCompanies(companies: Company[]) {
      for(const company of companies) {
        switch (company.id) {
          case 1:
            company.color = "#0369B3";
            break;
          case 3:
            company.color = "#059D76";
            break;
          case 7:
            company.color = "#CF1D27";
            break;
          case 8:
            company.color = "#CC1827";
            break;
          case 11:
            company.color = "#CC1827";
            break;
          case 13:
            company.color = "#1675BF";
            break;
          case 14:
            company.color = "#1675BF";
            break;
          case 15:
            company.color = "#0069B4";
            break;
          case 16:
            company.color = "#0369B3";
            break;
          default:
            company.color = "#8915b0";
            break;
        }
      }
      this.allCompanies = companies
    }
  }
})