import { Storage } from '@capacitor/storage'
import axios from 'axios' 

export const refreshLogin = async() => {
  try {
    const valueEmail: string | null = (await Storage.get({ key: 'email' })).value
    if(valueEmail === null) return
    const email: string = window.atob(valueEmail)
  
    const valuePassword: string | null = (await Storage.get({ key: 'password' })).value
    if(valuePassword === null) return
    const password: string = window.atob(valuePassword)

    const response = await axios.post(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + 'jwt/login', {
      email: email,
      password: password
    })

    await refreshUserData(response.data.access_token)

    await Storage.set({
      key: 'jwt',
      value: response.data.access_token
    })
  } catch (e) {
    console.error("Login ist Fehlgeschlagen", e)
  }
}

export const refreshUserData = async(jwtToken: string) => {
  const userdata: any = await axios.get(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + 'jwt/me', {
    headers: {
      Authorization: 'Bearer ' + jwtToken
    }
  })

  await Storage.set({
    key: 'userData',
    value: JSON.stringify(userdata.data.data)
  })
}