import UserData from '@/interfaces/UserData'
import { Storage } from '@capacitor/storage'
import { defineStore } from 'pinia'


export const useCurrentUser = defineStore('currentUser', {
  state: () => ({
    currentUser: {} as UserData,
    jwtKey: '' as string
  }),
  actions: {
    async setAll() {
      await Promise.all([
        this.setCurrentUserViaLocaleStorage(),
        this.setJwtKeyViaLocaleStorage()
      ])
    },
    async setCurrentUserViaLocaleStorage() {
      const currentUserString = (await Storage.get({ key: 'userData' })).value
      const currentUser: UserData = currentUserString ? JSON.parse(currentUserString) : undefined

      if(currentUser)
        this.currentUser = currentUser
    },
    async setJwtKeyViaLocaleStorage() {
      this.jwtKey = (await Storage.get({ key: 'jwt' })).value!
    },
  }
})