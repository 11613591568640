import Contact from '@/interfaces/Contact'
import Customer from '@/interfaces/customer/Customer'
import Document from '@/interfaces/Document'
import Project from '@/interfaces/project/Project'
import Subcustomer from '@/interfaces/subcustomer/Subcustomer'
import axios from 'axios'
import { defineStore } from 'pinia'


export const useSelectedProject = defineStore('selectedProject', {
  state: () => ({
    project: {} as Project,
    documents: [] as Document[],
    subcustomers: [] as Subcustomer[],
    customer: undefined as Customer | undefined,
    contact: undefined as Contact | undefined,
  }),
  actions: {
    async setAllViaApi(projectId: string, jwtKey: string, activeCompanyId: string) {
      await this.setProjectViaApi(jwtKey, activeCompanyId, projectId)
      await Promise.all([
        this.setCustomerViaApi(jwtKey, activeCompanyId),
        this.setContactViaApi(jwtKey, activeCompanyId),
        this.setSubcustomersViaApi(jwtKey, activeCompanyId),
        this.setDocumentsViaApi(jwtKey, activeCompanyId)
      ])
    },
    async setProjectViaApi(jwtKey: string, activeCompanyId: string, projectId?: string /* for refresh or not */) {
      const response: Project = (await axios.get(process.env.VUE_APP_PROJECT_API_BASE_URL + 'project/' + [projectId ? projectId : this.project._id] + "/?company=" + activeCompanyId, {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })).data.data
      response.formattedProjectNo = response.number.toString() // need to format number
      this.project = response
    },
    async setDocumentsViaApi(jwtKey: string, activeCompanyId: string) {
      const res = await axios.get(process.env.VUE_APP_ZEWOTHERM_API_BASE_URL + 'jwt/project/download-request?project_id=' + this.project._id + '&company=' + activeCompanyId, {
        headers: {
          Authorization: 'Bearer ' + jwtKey
        }
      })
      const documents: Document[] = res.data.data
      for(const document of documents) {
        if(document.signed_url.includes('.png') || document.signed_url.includes('.jpg') || document.signed_url.includes('.jpeg')) {
          document.path = document.signed_url
        } else if (document.signed_url.includes('.msg')) {
          document.path = './assets/fileTypes/msg.png'
        } else if (document.signed_url.includes('.pdf')) {
          document.path = './assets/fileTypes/pdf.png'
        } else if (document.signed_url.includes('.txt')) {
          document.path = './assets/fileTypes/txt.png'
        }
      }
      this.documents = documents
    },
    async setSubcustomersViaApi(jwtKey: string, activeCompanyId: string) {
      try {
        const subcustomers: string[] = this.project.subcustomers
        for(const subcustomerId of subcustomers) {
          const response: Subcustomer = (await axios.get(process.env.VUE_APP_CUSTOMER_API_BASE_URL + 'subcustomer/' + subcustomerId + "/?company_id=" + activeCompanyId, {
            headers: {
              Authorization: 'Bearer ' + jwtKey
            }
          })).data.data

          this.subcustomers.push(response)
        }
      } catch (e: any) {
        console.log("Fehler beim Laden der Beteiligten")
      }
    },
    async setCustomerViaApi(jwtKey: string, activeCompanyId: string) {
      try {
        if(!this.project.customer || this.project.customer === undefined || this.project.customer === null) return
        
        const response: Customer = (await axios.get(process.env.VUE_APP_CUSTOMER_API_BASE_URL + 'customer/' + this.project.customer + "/?company_id=" + activeCompanyId, {
          headers: {
            Authorization: 'Bearer ' + jwtKey
          }
        })).data.data
    
        this.customer = response    
      } catch (e: any) {
        console.log("Keinen Kunden für dieses Projekt gefunden")
      }
    },
    async setContactViaApi(jwtKey: string, activeCompanyId: string) {
      try {
        if(!this.project.contact || this.project.contact == undefined || this.project.contact == null) return
    
        const response: Contact = (await axios.get(process.env.VUE_APP_CUSTOMER_API_BASE_URL + 'customer/' + this.project.customer + '/contact/' + this.project.contact + "/?company_id=" + activeCompanyId, {
          headers: {
            Authorization: 'Bearer ' + jwtKey
          }
        })).data.data

        this.contact = response
      } catch (e: any) {
        console.log("Kein Ansprechpartner für dieses Projekt gefunden")
      }
    }
  }
})