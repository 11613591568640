import Construction from '@/interfaces/construction/Construction'
import Contact from '@/interfaces/Contact'
import Customer from '@/interfaces/customer/Customer'
import AddProjectActiveData from '@/interfaces/project/AddProjectActiveData'
import CreateProjectDto from '@/interfaces/project/CreateProjectDto'
import Subcustomer from '@/interfaces/subcustomer/Subcustomer'
import Subsidiary from '@/interfaces/Subsidary'
import axios from 'axios'
import { defineStore } from 'pinia'
import { useType } from './type'

export const useAddProject = defineStore('addProject', {
  state: () => ({
    projectActiveData: {
      name: "",
      type: "",
      status: "",
      area: "",
      area_unit: 'm2',
      construction: null,
      customer: null,
      subcustomers: [] as Subcustomer[],
      subsidiary: null,
      contact: null,
      additional_data: {}
    } as AddProjectActiveData,
    errorResponse: {} as any,
    contactToChoose: [] as Contact[],
    activeCompanyForCustomers: "" as string,
    subsidiaryToChoose: [] as Subsidiary[]
  }),
  actions: {
    setAll(activeCompanyId: string) {
      this.setActiveCompanyForCustomers(activeCompanyId)
    },
    setActiveCompanyForCustomers(newId: string) {
      this.activeCompanyForCustomers = newId
    },
    setProjectActiveDataName(name: string) {
      this.projectActiveData.name = name
    },
    setProjectActiveType(type: string) {
      this.projectActiveData.type = type
      this.setProjectActiveDataAddtitionalData()
    },
    setProjectActiveDataAddtitionalData() {
      const typeStore = useType()
      this.projectActiveData.additional_data = {}

      const additionalDataType = typeStore.getOneAdditionalDataType(this.projectActiveData.type)
      if(!additionalDataType) return

      for(const field of additionalDataType.fields) {
        switch(field.type) {
          case 'array': 
            this.projectActiveData.additional_data[field.name] = []
            break
          case 'date':
            if(field.default !== '')
              this.projectActiveData.additional_data[field.name] = field.default.split('.')[2] + '-' + field.default.split('.')[1] + '-' + field.default.split('.')[0]
            else
              this.projectActiveData.additional_data[field.name] = field.default
            break
          default:
            this.projectActiveData.additional_data[field.name] = field.default
        }
      }
    },
    setProjectActiveDataAddtitionalDataField(fieldName: string, value: string, subfield?: string, index?: number) {
      if(subfield && index) {
        console.log(fieldName, subfield, index)
        this.projectActiveData.additional_data[subfield][index][fieldName] = value
      } else {
        this.projectActiveData.additional_data[fieldName] = value
      }
    },
    setProjectActiveDataStatus(status: string) {
      this.projectActiveData.status = status
    },
    setProjectActiveDataArea(area: string) {
      this.projectActiveData.area = area
    },
    setProjectActiveDataAreaUnit(area_unit: string) {
      this.projectActiveData.area_unit = area_unit
    },
    setProjectActiveDataConstruction(construction: Construction | null) {
      this.projectActiveData.construction = construction
    },
    addOrRemoveProjectActiveDataSubcustomer(subcustomer: Subcustomer) {
      const foundIndex = this.projectActiveData.subcustomers.findIndex((sub) => {
        return sub._id === subcustomer._id
      })
      foundIndex === -1 ? this.projectActiveData.subcustomers.push(subcustomer) : this.projectActiveData.subcustomers.splice(foundIndex, 1)
    },
    setProjectActiveDataSubsidiary(subsidiary: Subsidiary) {
      this.projectActiveData.subsidiary = subsidiary
    },
    setProjectActiveDataContact(contact: Contact | null) {
      this.projectActiveData.contact = contact
    },
    async setProjectActiveDataCustomer(customer: Customer | null, jwtKey: string) {
      this.projectActiveData.customer = customer
      if(customer == null) {
        this.subsidiaryToChoose = []
        this.contactToChoose = []
        return
      }
      await this.getAndSetSubsidiaryToChoose(customer._id, jwtKey)
      await this.getAndSetContactToChoose(customer._id, jwtKey)
    },
    async getAndSetSubsidiaryToChoose(customerId: string, jwtKey: string) {
      try {
        const response = await axios.get(process.env.VUE_APP_CUSTOMER_API_BASE_URL + "customer/" + customerId + "/subsidiary" + "/?company_id=" + this.activeCompanyForCustomers, {
          headers: {
            Authorization: 'Bearer ' + jwtKey
          }
        })
        this.subsidiaryToChoose = response.data.data
      } catch (e) {
        this.subsidiaryToChoose = []
      }
      
    },
    async getAndSetContactToChoose(customerId: string, jwtKey: string) {
      try {
        const response = await axios.get(process.env.VUE_APP_CUSTOMER_API_BASE_URL + "customer/" + customerId + "/contact" + "/?company_id=" + this.activeCompanyForCustomers, {
          headers: {
            Authorization: 'Bearer ' + jwtKey
          }
        })
        this.contactToChoose = response.data.data
      } catch (e) {
        this.contactToChoose = []
      }
    },
    async saveProject(companyId: string, currentUserId: string, jwtKey: string): Promise<string | undefined> {
      try {
        const contact: Contact | undefined = this.projectActiveData.contact == null ? undefined : this.projectActiveData.contact
        const subsidiary: Subsidiary | undefined = this.projectActiveData.subsidiary == null ? undefined : this.projectActiveData.subsidiary
        const area = parseInt(this.projectActiveData.area)
    
        const dataToUpload: CreateProjectDto = {
          name: this.projectActiveData.name,
          type: this.projectActiveData.type,
          status: this.projectActiveData.status,
          area: area,        
          area_unit: this.projectActiveData.area_unit,
          contact: contact ? contact._id : undefined,
          customer: this.projectActiveData.customer == null ? "" : this.projectActiveData.customer._id,
          construction: this.projectActiveData.construction == null? "" : this.projectActiveData.construction._id,
          subcustomers: this.projectActiveData.subcustomers.map((subcustomer) => subcustomer._id),
          subsidiary: subsidiary ? subsidiary._id : undefined,
          company: companyId,
          created_by: currentUserId,
          additional_fields: this.projectActiveData.additional_data
        }
    
        const response = await axios.post(process.env.VUE_APP_PROJECT_API_BASE_URL + 'project', dataToUpload, {
          headers: {
            Authorization: 'Bearer ' + jwtKey
          }
        })
        this.$reset()
        return response.data.data._id
      } catch (e: any) {
        console.error(e)
        this.errorResponse = e.response.data.errors
      }
    }
  }
})