import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue'
import Login from '@/views/LoginPage.vue'
import AddProject from '@/views/AddProjectPage.vue'
import Detail from '@/views/DetailPage.vue'
import Data from '@/views/DetailPageTabs/DataPage.vue'
import Checkpoints from '@/views/DetailPageTabs/CheckpointsPage.vue'
import Document from '@/views/DetailPageTabs/DocumentPage.vue'
import Teams from '@/views/DetailPageTabs/TeamsPage.vue'
import Comments from '@/views/DetailPageTabs/CommentsPage.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/addProject',
    name: 'AddProject',
    component: AddProject
  },
  {
    path: '/detail/',
    name: 'Detail',
    component: Detail,
    children: [
      {
        path: ':id/data',
        name: 'Daten',
        component: Data,
      },
      {
        path: ':id/checkpoints',
        name: 'Checkpoints',
        component: Checkpoints,
      },
      {
        path: ':id/document',
        name: 'Dokumente',
        component: Document,
      },
      {
        path: ':id/teams',
        name: 'Teams',
        component: Teams,
      },
      {
        path: ':id/comments',
        name: 'Kommentare',
        component: Comments,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import { Storage } from '@capacitor/storage'
import { refreshLogin } from '@/functions/refreshLogin'
import jwt_decode from 'jwt-decode'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

router.beforeEach(async(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const email = (await Storage.get({ key: 'email' })).value
  const password = (await Storage.get({ key: 'password' })).value

  const decodedJwtToken = (await Storage.get({ key: 'jwt' })).value
  let jwtToken: any
  if(!decodedJwtToken) jwtToken = null
  else jwtToken = await jwt_decode(decodedJwtToken)


  if(jwtToken === '' || !jwtToken) {
    if(email !== '' && email && password !== '' && password) {
      try {
        await refreshLogin()
        if(to.path === '/') next()
        else next('/')
      } catch (e) {
        if(to.path === '/login') next()
        else next('/login')
      }
    } else {
      if(to.path === '/login') next()
      else next('/login')
    }
  } else if(parseInt((new Date(Date.now()).getTime() / 1000).toFixed(0)) >= parseInt(jwtToken.exp)) {
    if(email !== '' && email && password !== '' && password) {
      try {
        await refreshLogin()
        if(to.path === '/') next()
        else next('/')
      } catch(e) {
        if(to.path === '/') next()
        else next('/')
      }
    } else {
      if(to.path === '/login') next()
      else next('/login')
    }
  } else if((await Storage.get({ key: 'userData' })).value === '' || !(await Storage.get({ key: 'userData' })).value) {
    try {
      await refreshLogin()
      if(to.path === '/') next()
      else next('/')
    } catch(e) {
      if(to.path === '/') next()
      else next('/')
    }
  } else {
    next()
  }
})

export default router