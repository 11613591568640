import Filter from '@/interfaces/Filter'
import { defineStore } from 'pinia'

export const useFilter = defineStore('filter', {
  state: () => ({
    filters: [
      { titel: 'Status', value: '', defaultValue: '' },
      {
        titel: 'Wiedervorlage', 
        value: '',
        defaultValue: '',
        options: [
          { titel: 'Enthält Wiedervorlage', value: 'resubmission' },
          { titel: 'Enthält keine Wiedervolage', value: '!resubmission' },
        ]
      },
      {
        titel: 'Angebotssumme', 
        value: '',
        defaultValue: '',
        options: [
          { titel: 'kleiner 5.000 €', value: 'lt5k' },
          { titel: 'größer 5.000 €', value: 'gt5k' },
          { titel: 'größer 10.000 €', value: 'gt10k' },
        ]
      },
      { titel: 'Vertrieb', value: '', defaultValue: '' },
      { titel: 'Projektleiter', value: '', defaultValue: '' },
      { titel: 'Typ', value: '', defaultValue: '' },
      { titel: 'Tags', value: '', defaultValue: '' },
      { titel: 'Enthält Checkpoint', value: '', defaultValue: '' },
      { titel: 'Fehlender Checkpoint', value: '', defaultValue: '' },
      { titel: 'Berechner', value: '', defaultValue: '' }, // calculator
      { titel: 'Team', value: '', defaultValue: '' }
    ] as Filter[],
  }),
  getters: {
    activeFilters(): Filter[] {
      return this.filters.filter((filter: Filter) => {
        return filter.value !== filter.defaultValue
      })
    }
  },
  actions: {
    setFilter(filter: Filter, newValue: string) {
      const foundFilter = this.filters.find(item => item.titel === filter.titel)
      if(foundFilter)
        foundFilter.value = newValue
    },
    deactivateFilter(filter: Filter) {
      const index: number = this.filters.findIndex((item) => item.titel === filter.titel)
      if(index !== -1) this.filters[index].value = this.filters[index].defaultValue
      else console.error("Filter not found")
    }
  }
})